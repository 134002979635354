import { Component, OnDestroy, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Router } from '@angular/router';
import { navItems } from '../../_nav';
import { DataService } from '../../data.service';
import { AdminauthService } from '../../services/adminauth.service';
import { environment } from '../../../environments/environment';
import 'rxjs/Rx';

@Component({
     selector: 'app-dashboard',
  templateUrl: './default-layout.component.html',
  styleUrls: ['./default-layout.component.scss']
})
export class DefaultLayoutComponent implements OnDestroy {
     url_key = environment.url_key
     public navItems = navItems;
     public sidebarMinimized = true;
     private changes: MutationObserver;
     public element: HTMLElement;
     user_id: any; msg: any;
     //private dataService: DataService;
     siteInfo: any = {};
     profileImg = 'assets/avatar2.png';
     navbaritems: any = [];
     navItemsModule: any = [];
     token = localStorage.getItem('Key');
     admin_notify_data = [];
     unread_msg_count = 0;
     constructor(private router: Router, private authService: AdminauthService, private dataService: DataService, @Inject(DOCUMENT) _document?: any) {

          this.dataService.data.subscribe(resData => {
               if (JSON.stringify(resData) != "{}") { this.msg = resData; this.profileImg = this.msg; };
          });
          this.dataService.sitesetting.subscribe(resData => {
               if (JSON.stringify(resData) != "{}") { this.msg = resData; this.siteInfo = this.msg };
          });
          //Get admin details
          // this.dataService.getRequest('admin/moduleaccess', this.token).subscribe((resData2: any) => {
          //      var array: Array<string> = resData2.data.access_module;
          //      if (array.length != 0) {
          //           this.navbaritems.push(this.navItems[0])
          //           for (var i = this.navItems.length - 1; i >= 0; i--) {
          //                if (array.indexOf(this.navItems[i].module) > -1) {
          //                     this.navbaritems.push(this.navItems[i])
          //                }
          //           }
          //           this.navItems = this.navbaritems;
          //      }
          // });

          this.changes = new MutationObserver((mutations) => {
               this.sidebarMinimized = _document.body.classList.contains('sidebar-minimized');
          });
          this.element = _document.body;
          this.changes.observe(<Element>this.element, {
               attributes: true,
               attributeFilter: ['class']
          });


     }



     logout() {
          localStorage.clear();
          this.router.navigate(['/' + this.url_key]);
     }

     ngOnInit() {
          // this.dataService.getRequest('admin/settings', this.token).subscribe((reqData: any) => {
          //      if (reqData.status == true) {
          //           this.siteInfo = reqData.data;
          //      }
          // });

          // this.dataService.getRequest('admin/profile', this.token).subscribe((reqData: any) => {
          //      if (reqData.data.profileimg) {
          //           this.profileImg = reqData.data.profileimg;
          //      }
          // });
          this.get_last_admin_notification()
     }

     get_last_admin_notification() {
          this.dataService.getData('admin/show_notification').subscribe((res: any) => {
               if (res.status) {
                    this.unread_msg_count = res.data.length == 0 ? null : res.data.length
                    this.admin_notify_data = res.data
               }
          })
     }

     mark_as_read(value, option: any) {
          var url = option.title == 'fiat_withdraw_request' ? 'withdrawhistory' : option.title == 'fiat_deposit_request' ? 'deposit' : option.title == 'crypto_withdraw_request' ? 'pendingwithdrawhistory' : option.title == 'kyc_bank_submitted' ? 'view-users' : 'dashboard'
          var type = option.title == 'kyc_bank_submitted' ? 'pendingKyc' : 'pending'
          this.router.navigate(['/' + this.url_key + '/pages' + '/' + url], { queryParams: { type: type } })
          let params = {
               '_id': value != 'all' ? value : null,
               'All': value == 'all' ? value : null
          }
          this.dataService.postUrl('admin/update_notification', params).subscribe((response) => {
               console.log("🚀 ~ file: default-layout.component.ts:99 ~ DefaultLayoutComponent ~ this.dataService.postUrl ~ response", response)
               this.get_last_admin_notification();
          })
     }

     ngOnDestroy(): void {
          this.changes.disconnect();
     }

}

