import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { DataService } from '../data.service';
import { AdminauthService } from '../services/adminauth.service';
import { environment } from '../../environments/environment'


@Injectable({
  providedIn: 'root'
})
export class AuthGuardGuard implements CanActivate {
	constructor(private dataService: DataService, private authService: AdminauthService,private router: Router){}
  	canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    
    // if(this.authService.isAuthenticated()) {      
    //   return true;
    // }else{ 
    // this.router.navigateByUrl('/'+environment.url_key);
    // return false;
    // }
    if (!this.authService.isAuthenticated()) {
        localStorage.removeItem('Key');
        localStorage.removeItem('expires_at');        
        this.router.navigateByUrl('/'+environment.url_key);
        return false;
      } else {
        return true;
      }
  
  }
  
}
