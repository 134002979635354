import { Component, HostListener, OnInit } from '@angular/core';
import { getCheckNoChangesMode } from '@angular/core/src/render3/state';
import { Router, NavigationEnd } from '@angular/router';
import { DataService } from './data.service';

@Component({
  selector: 'body',
  template: '<router-outlet></router-outlet>'
})
export class AppComponent implements OnInit {

  constructor(private router: Router, private dataService: DataService) {


    if(this.dataService.loggedIn()){
        this.dataService.idleLogout();
    }  
  }

  @HostListener('contextmenu', ['$event'])
  onRightClick(event) {
      //  event.preventDefault();
  }

  @HostListener('document:keydown', ['$event']) onKeydownHandler(event: KeyboardEvent) {
       if (event.keyCode == 44 || event.keyCode === 123) {
            event.preventDefault()
       }
  }   

  ngOnInit() {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });

    
  }
}
