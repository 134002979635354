import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators, NgForm } from '@angular/forms';
import { NotifierService } from 'angular-notifier';
import { DataService } from '../../data.service';
import { AdminauthService } from '../../services/adminauth.service';
import { environment } from '../../../environments/environment'


import PatternLock from 'patternlock';
import * as $ from 'jquery';

@Component({
	selector: 'app-dashboard',
	templateUrl: 'login.component.html'
})
export class LoginComponent implements OnInit {
	postRequest = false;
	loginForm: FormGroup;
	submitted = false;
	returnUrl: string;
    ipblock=true; 
	private notifier: NotifierService;

	constructor(
		private formBuilder: FormBuilder,
		private router: Router,
		private dataService: DataService,
		notifier: NotifierService,
		private auth: AdminauthService
			) { 
	
		   
		this.notifier = notifier;
		this.returnUrl = this.dataService.adminUrl()+'pages/dashboard';

		if(this.dataService.getSession('id')) {
			this.router.navigateByUrl ( this.returnUrl );
		}
	}

	ngOnInit() {
		this.loginForm = this.formBuilder.group({
			email: ['', [Validators.required, Validators.pattern]],
			password: ['', [Validators.required, Validators.minLength(6)]],
		});

	//patternlock
	var lock = new PatternLock("#patternContainer",{
      onDraw:function(pattern){
        var pat = lock.getPattern();
        $("#patterncode").val(pat);
	        this.patterncode=pat;           
			}
	});

	}

	get f() { return this.loginForm.controls; }

	onSubmit() {	
			this.submitted = true;
		if (this.loginForm.invalid) {
			return;
		}
		var loginObj:any;
		loginObj=this.loginForm.value;
		if($("#patterncode").val()==""){
			this.notifier.notify( 'error', "Please draw pattern");
			return;
		}
		loginObj.pattern=$("#patterncode").val();
		this.postRequest = true;

		this.dataService.chklogin(loginObj).subscribe((resData:any) => {
			if(resData.status == true) {				 					
				this.notifier.notify( 'success', resData.success);
        this.auth.setSession(resData.Key);
				setTimeout(() => {
					return this.router.navigateByUrl(this.returnUrl);
				}, 600);

			}else if (resData.status == false){
				this.notifier.notify( 'error', resData.error);
			}	else {
				this.postRequest = false;
				this.notifier.notify( 'error', resData.error);
				if(resData.status == 401){
					this.router.navigate(['/'+environment.url_key]);
				}
			}
		this.postRequest = false;	
		});
	// }else{
	// 			this.notifier.notify( 'error', 'your ip was blocked');	 			
	//  }
	}

	

}