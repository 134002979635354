import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
// Import Containers
import { DefaultLayoutComponent } from './containers';

import { AuthGuardGuard } from './guards/auth-guard.guard';

import { P404Component } from './views/error/404.component';
import { P500Component } from './views/error/500.component';
import { LoginComponent } from './views/login/login.component';
import { LogoutComponent } from './views/logout/logout.component';
import { environment } from "../environments/environment";
var url_key = environment.url_key
export const routes: Routes = [
  {
    path: '',
    redirectTo: url_key + '',
    pathMatch: 'full'
  },
  {
    path: 'logout',
    component: LogoutComponent,
    canActivate: [AuthGuardGuard]
  },
  {
    path: url_key + '/pages',
    redirectTo: url_key + '/pages/dashboard',
    pathMatch: 'full',
    canActivate: [AuthGuardGuard],
  },
  {
    path: 'dashboard',
    redirectTo: 'dashboard',
    pathMatch: 'full',
    canActivate: [AuthGuardGuard],
  },
  {
    path: '404',
    component: P404Component,
    data: {
      title: 'Page 404'
    }
  },
  {
    path: '500',
    component: P500Component,
    data: {
      title: 'Page 500'
    }
  },
  {
    path: url_key + '',
    component: LoginComponent,
    data: {
      title: 'Login Page'
    }
  },
  {
    path: url_key + '/pages',
    component: DefaultLayoutComponent,
    data: {
      title: 'Home'
    },
    children: [
      {
        path: '',
        loadChildren: './views/pages/pages.module#PagesModule'
      },
      {
        path: 'dashboard',
        loadChildren: './views/dashboard/dashboard.module#DashboardModule'
      }
    ]
  },
  { path: '**', component: P404Component }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true }), CommonModule],
  exports: [RouterModule]
})

export class AppRoutingModule { }

export const routingComponents = [LoginComponent, P404Component, P500Component, LogoutComponent, DefaultLayoutComponent]
