import { Injectable } from '@angular/core';
import { DataService } from '../data.service';
import * as moment from "moment";
import { JwtHelperService } from '@auth0/angular-jwt';
@Injectable({
  providedIn: 'root'
})
export class AdminauthService {

isAuth = false;

  constructor(private dataService: DataService, public jwtHelper: JwtHelperService) { }

  setSession(Key) {
		localStorage.setItem('Key', Key);
	}       

	deleteToken() {
		localStorage.removeItem("Key");		
	}

	public getToken(): string {
		return localStorage.getItem('Key');
	}	
	
	loggedIn() {
		return !!localStorage.getItem('Key');
	}

	public isAuthenticated(): boolean {
    const token = localStorage.getItem('Key');  
    return !this.jwtHelper.isTokenExpired(token);
  }
	
}
