import {environment} from "../environments/environment";

var url_key = environment.url_key
interface NavAttributes {
    [propName: string]: any;
}
interface NavWrapper {
    attributes: NavAttributes;
    element: string;
}
interface NavBadge {
    text: string;
    variant: string;
}
interface NavLabel {
    class?: string;
    variant: string;
}

export interface NavData {
    module?: string;
    name?: string;
    url?: string;
    icon?: string;
    badge?: NavBadge;
    title?: boolean;
    children?: NavData[];
    variant?: string;
    attributes?: NavAttributes;
    divider?: boolean;
    class?: string;
    label?: NavLabel;
    wrapper?: NavWrapper;
}

let Data = [
    {
        name: 'Dashboard',
        url: '/' + url_key + '/pages/dashboard',
        icon: 'icon-speedometer'
    },
    {
        name: 'Manage Stake',
        url: '/' + url_key + '/pages/stake',
        icon: 'icon-speedometer'
    },
    {
        module: 'User Stake History',
        name: 'User Stake History',
        url: '/base',
        icon: 'fa fa-signal',
        children: [
            {
                name: 'Fixed Stake',
                url: '/' + url_key + '/pages/fixed',
                icon: 'fa fa-align-left'
            },
            {
                name: 'Flexible Stake',
                url: '/' + url_key + '/pages/flexible',
                icon: 'fa fa-align-left'
            },
            {
                name: 'Compound Stake',
                url: '/' + url_key + '/pages/compound',
                icon: 'fa fa-align-left'
            },
        ]
    },
    {
        module: 'User Stake Interest History',
        name: 'User Stake Interest History',
        url: '/base',
        icon: 'fa fa-signal',
        children: [
            {
                name: 'Fixed Stake Interest',
                url: '/' + url_key + '/pages/fixed-interest',
                icon: 'fa fa-align-left'
            },
            {
                name: 'Flexible Stake Interest',
                url: '/' + url_key + '/pages/flexible-interest',
                icon: 'fa fa-align-left'
            },
            {
                name: 'Compound Stake Interest',
                url: '/' + url_key + '/pages/compound-interest',
                icon: 'fa fa-align-left'
            },
        ]
    },
    {
        name: 'Referral Commission',
        url: '/' + url_key + '/pages/referralCommission',
        icon: 'fa fa-money'
    },

    {
        module: 'Manage Copay Card',
        name: 'Manage Copay Card',
        url: '/base',
        icon: 'fa fa-align-justify',
        children: [
          {
            name: 'List Copay Card',
            url: '/' + url_key + '/pages/copay-user',
            icon: 'fa fa-user'
          },
          {
            name: 'Create Copay Card',
            url: '/' + url_key + '/pages/copay',
            icon: 'fa fa-credit-card'
          },
          {
            name: 'Copay Topup Requests',
            url: '/' + url_key + '/pages/copay-transaction',
            icon: 'fa fa-align-left'
          },
          {
            name: 'Copay Card Transaction',
            url: '/' + url_key + '/pages/copay-card-transaction',
            icon: 'fa fa-align-left'
          },

          {
            name: 'Copay Lock Rewards',
            url: '/' + url_key + '/pages/lock-transaction',
            icon: 'fa fa-align-left'
          },
          {
            name: 'Card Types',
            url: '/' + url_key + '/pages/manageUpgrateCard',
            icon: 'fa fa-money'
          },
        ]
    },
    {
        name: 'Manage Network',
        url: '/' + url_key + '/pages/network',
        icon: 'icon-speedometer'
    },

    {
        module: 'Transactions',
        name: 'Transactions',
        url: '/base',
        icon: 'fa fa-arrows',
        children: [
            {
                name: 'Fiat Deposit History',
                url: '/' + url_key + '/pages/deposit',
                icon: 'fa fa-align-left'
            },
            {
                name: 'Fiat Withdraw History',
                url: '/' + url_key + '/pages/withdrawhistory',
                icon: 'fa fa-align-left'
            },
            // {
            //     name: 'Crypto Deposit',
            //     url: '/' + url_key + '/pages/admin-deposit',
            //     icon: 'fa fa-align-left'
            // },
            // {
            //     name: 'Crypto Withdraw',
            //     url: '/' + url_key + '/pages/admin-withdraw',
            //     icon: 'fa fa-align-left'
            // },
        ]
    },
    {
        module: 'Trade Management',
        name: 'Trade Management',
        url: '/base',
        icon: 'fa fa-signal',
        children: [
          {
            name: 'Trade Pairs',
            url: '/' + url_key + '/pages/pairs',
            icon: 'fa fa-exchange'
          },
            {
                name: 'Trade History',
                url: '/' + url_key + '/pages/tradehistory',
                icon: 'fa fa-align-left'
            },
            {
                name: 'Order History',
                url: '/' + url_key + '/pages/orderhistory',
                icon: 'fa fa-align-left'
            },
            {
                name: 'Cancel History',
                url: '/' + url_key + '/pages/cancelhistory',
                icon: 'fa fa-align-left'
            },
        ]
    },
    {
        module: 'Currency',
        name: 'Currency',
        url: '/' + url_key + '/pages/currency',
        icon: 'fa fa-money'
    },
    {
        module: 'Bank Details',
      name: 'Bank Details',
      url: '/' + url_key + '/pages/bank-details',
      icon: 'fa fa-money'
    },

  {
    module: 'Banner',
    name: 'Staking Banner',
    url: '/' + url_key + '/pages/banner',
    icon: 'fa fa-exchange'
  },
  {
    name: 'Announcements',
    url: '/' + url_key + '/pages/announcement',
    icon: 'fa fa-money'
  },

];

export const navItems: NavData[] = Data;
